import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { default as AntdRate, RateProps as AntRateProps } from 'antd/lib/rate';

import { defaultColorSet } from '@app/objects/ColorSet';
import { ApplicationState } from '@app/store';

type RateStyles = Omit<React.CSSProperties, 'color'>;

interface Props extends Omit<AntRateProps, 'styles'> {
	style: RateStyles;
}

const Rate: React.FC<Props> = ({ style, ...props }) => {
	const siteSettings = useSelector((state: ApplicationState) => state.siteViewSettings.item, shallowEqual);
	const colorSet = siteSettings?.colorSet ?? defaultColorSet;

	return <>
		<AntdRate
			{...props}
			style={{
				...style,
				color: colorSet.baseColor4,
			}}
		/>
	</>;
};

export default Rate;
