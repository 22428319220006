import React from 'react';

import BurgerMenu from '@app/components/UI/BurgerMenu/BurgerMenu';
import SiteLinks from '@app/components/UI/SiteLinks/SiteLinks';
import { SocialMediaLinks } from '@app/objects/Location';

const LeftPanel: React.FC<{socialMediaLinks: SocialMediaLinks}> = ({ socialMediaLinks }) => {
	return <div className="left-panel">
		<BurgerMenu socialMediaLinks={socialMediaLinks} />
		<SiteLinks socialMediaLinks={socialMediaLinks} />
	</div>;
};

export default LeftPanel;
