import * as React from 'react';

import Button from '@common/react/components/Forms/Button';

interface Props {
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	title?: string;
}

const CircleButton: React.FC<Props> = ({ onClick, title = 'Book appointment' }) => {
	return <Button
		className="btn circle-button"
		title={title}
		type="button"
		onClick={onClick}
	>
		<div className="circle-button__inner">
			<svg viewBox="10 10 80 100">
				<path d="M 0,50 a 50,50 0 1,1 0,1 z" id="circle" />
				<text>
					<textPath href="#circle">
						Book • your • appointment • today •
					</textPath>
				</text>
			</svg>
		</div>
	</Button>;
};

export default CircleButton;
