import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Button from '@common/react/components/Forms/Button';

import HorizontalArrow from '@app/components/UI/Icons/HorizontalArrow';
import { defaultColorSet } from '@app/objects/ColorSet';
import { ApplicationState } from '@app/store';

interface Props {
	onClick: () => void;
}

const LeftArrow: React.FC<Props> = ({ onClick }) => {
	const siteSettings = useSelector((state: ApplicationState) => state.siteViewSettings.item, shallowEqual);
	const colorSet = siteSettings?.colorSet ?? defaultColorSet;

	return <>
		<Button className="btn btn-arrow left-arrow" onClick={onClick}>
			<HorizontalArrow fill={colorSet.baseColor4} />
		</Button>
	</>;
};

export default LeftArrow;
