import React from 'react';

import { Rows } from '@common/typescript/objects/List';

import { Faq } from '@commonTuna/react/objects/Faq';

import MoreButton from '@app/components/UI/MoreButton/MoreButton';
import FaqsCollapse from '@app/components/UI/FaqsCollapse/FaqsCollapse';

import faqsImg from '@images/faqs.jpg';

const FaqsSection: React.FC<{faqs: Rows<Faq>}> = ({ faqs }) => {
	return <section className="faq" id="faq">
		<div className="faq__info">
			<h2 className="faq__title section-title">FAQ</h2>
			<FaqsCollapse faqs={faqs.list} shortAnswer />
			<MoreButton to="/faq" visible={faqs.list?.length < faqs.count} />
		</div>
		<div className="faq__image">
			<img src={faqsImg} alt="Beautiful women" />
		</div>
	</section>;
};

export default FaqsSection;
