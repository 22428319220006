import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import ServerPageProvider from '@common/react/components/Core/ServerPageProvider/ServerPageProvider';
import { Lang } from '@common/typescript/objects/Lang';
import { PageInfo } from '@common/typescript/objects/Page';

import MainBlock from '@app/components/Pages/Home/MainBlock';
import { Location, SocialMedia, SocialMediaLinks } from '@app/objects/Location';
import Doctors from '@app/components/Pages/Home/Doctors';
import AboutUs from '@app/components/Pages/Home/AboutUs';
import BlogSection from '@app/components/Pages/Home/BlogSection';
import Services from '@app/components/Pages/Home/Services';
import FaqsSection from '@app/components/Pages/Home/FaqsSection';
import Reviews from '@app/components/Pages/Home/Reviews';
import Cases from '@app/components/Pages/Home/Cases';
import { ApplicationState } from '@app/store';

const HomeInner: React.FC<{home}> = (props) => {
	const location: Location = useSelector((state: ApplicationState) => state.location.item, shallowEqual);

	const aboutUsPage = props.home?.item?.aboutUsPage;
	const aboutUs: PageInfo | undefined = aboutUsPage?.langs?.find((el) => el.language === Lang.En) ?? aboutUsPage?.langs[0];

	const getSocialMediaLink = (socialMedias: Array<SocialMedia>, pattern: string): string => {
		const socialMedia: SocialMedia | undefined = socialMedias.find((item) => item.link.includes(pattern));
		return socialMedia?.link ?? '';
	};

	const socialMediaLinks: SocialMediaLinks = {
		instagram: getSocialMediaLink(location.socialMedias, 'instagram'),
		facebook: getSocialMediaLink(location.socialMedias, 'facebook'),
	};

	return <div className="home-page">
		<MainBlock socialMediaLinks={socialMediaLinks} specials={props.home?.item?.specials} title={aboutUs?.title ?? ''} />
		<AboutUs description={aboutUs?.body ?? ''} avatar={aboutUsPage?.originalAvatar} />
		<Services servicePages={props.home?.item?.servicePages} />
		<Doctors doctors={props.home?.item?.doctors} />
		<Cases cases={props.home?.item?.cases} />
		<Reviews reviews={props.home?.item?.reviews} />
		<FaqsSection faqs={props.home?.item?.faqs} />
		<BlogSection articles={props.home?.item?.articles} />
	</div>;
};

const Home: React.FC = () => {
	return (
		<ServerPageProvider
			path="home"
			inner={(page) => {
				return <HomeInner home={page} />;
			}}
		/>
	);
};

export default Home;
