import React from 'react';

import Slider from 'react-slick';

import { BaseSpecial } from '@commonTuna/react/objects/BaseSpecial';

import ImageWithLoader from '@app/components/UI/ImageWithLoader/ImageWithLoader';
import TextWithEllipsis from '@app/components/UI/TextWithEllipsis/TextWithEllipsis';

const SpecialsSlider: React.FC<{specials: Array<BaseSpecial>}> = ({ specials }) => {
	return <Slider
		dots
		slidesToShow={1}
		slidesToScroll={1}
		className="specials-carousel"
		arrows={false}
		fade
		lazyLoad="ondemand"
	>
		{specials.map((special) => <React.Fragment key={special.id}>
			<div className="specials-carousel__item">
				<div className="specials-carousel__left">
					<ImageWithLoader
						containerClassName="special-image"
						viewBox="0 0 140 205"
						src={special.originalAvatar}
						alt={special.name}
						uniqueKey={special.id.toString()}
						rectProps={{ rx: '100', ry: '70' }}
					/>
				</div>
				<div className="specials-carousel__right">
					<h3>{special.name}</h3>
					<TextWithEllipsis
						text={special.description}
						count={170}
						className="special-description"
					/>
				</div>
			</div>
		</React.Fragment>)}
	</Slider>;
};

export default SpecialsSlider;
