import React from 'react';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';

const MoreButton: React.FC<{to: string, visible: boolean}> = ({ to, visible }) => {
	return <>
		{visible && <div className="more-button">
			<LinkWithPrevLocation to={to}>See more</LinkWithPrevLocation>
		</div>
		}
	</>;
};

export default MoreButton;
