import React from 'react';
import Slider from 'react-slick';

import { Review } from '@commonTuna/react/objects/Review';

import Background from '@app/components/UI/Background/Background';
import { getSettings } from '@app/utils/getCarouselSettings';
import Rate from '@app/components/UI/Rate/Rate';

const Reviews: React.FC<{reviews: Array<Review>}> = ({ reviews }) => {
	const fullScreenSlidesToShow = reviews.length < 3 ? reviews.length : 3;

	const currentDate = new Date();
	const today = currentDate.getDate();
	const currentMonth = currentDate.getMonth();
	const currentYear = currentDate.getFullYear();

	const getTime = (time: number): string => {
		const reviewDate = new Date(time);

		if (reviewDate === currentDate) {
			return 'today';
		}

		if (reviewDate.getFullYear() === currentYear) {
			if (reviewDate.getMonth() === currentMonth) {
				const count = today - reviewDate.getDate();
				return `${count} days ago`;
			}

			const count = currentMonth - reviewDate.getMonth();
			return `${count} months ago`;
		}

		const count = currentYear - reviewDate.getFullYear();

		return `${count} years ago`;
	};

	return <section className="reviews relative-container" id="reviews">
		<Background count={4}>
			<div className="container">
				<h2 className="reviews__title section-title">Reviews</h2>
				<Slider
					dots={false}
					slidesToShow={fullScreenSlidesToShow}
					slidesToScroll={1}
					className="reviews-carousel"
					responsive={[getSettings(992, 1, reviews.length)]}
					arrows
					centerMode
					centerPadding="0px"
					infinite
				>
					{reviews.map((review) => <div key={review.id} className="reviews-carousel__item">
						<Rate
							value={review.rate}
							character={<span>✦</span>}
							style={{ fontSize: '16px' }}
							className="reviews-rate"
							disabled
						/>
						<h4 className="reviews-text">{review.textEn}</h4>
						<div className="reviews-author">
							<div className="reviews-author__avatar">
								{review.author ? review.author.slice(0, 1) : 'A'}
							</div>
							<div className="reviews-author__info">
								<h3>{review.author || 'Anonymous'}</h3>
								<h4>{getTime(review.time)}</h4>
							</div>
						</div>
					</div>)}
				</Slider>
			</div>
		</Background>
	</section>;
};

export default Reviews;
