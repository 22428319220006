import * as React from 'react';

import BookAppointmentForm from '@app/components/UI/BookAppointmentForm/BookAppointmentForm';
import CircleButton from '@app/components/UI/CircleButton/CircleButton';
import Modal from '@app/components/UI/Modal/Modal';

const BookAppointmentModal: React.FC = () => {
	const [open, setOpen] = React.useState<boolean>(false);

	const toggle = (): void => {
		setOpen(!open);
	};

	return <>
		<CircleButton onClick={toggle} />
		<Modal
			width="700px"
			centered
			open={open}
			className="book-apt-modal"
			onOk={toggle}
			onCancel={toggle}
			footer={null}
			destroyOnClose
		>
			<div className="book-apt-modal__form">
				<BookAppointmentForm />
			</div>
		</Modal>
	</>;
};

export default BookAppointmentModal;
