import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ContentLoader from 'react-content-loader';

import ImageWithSkeleton from '@commonTuna/react/components/UI/ImageWithSkeleton/ImageWithSkeleton';
import imageSkeleton from '@commonTuna/react/images/no-image-available.jpg';

import { ApplicationState } from '@app/store';
import { defaultColorSet } from '@app/objects/ColorSet';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
	src: string;
	alt: string;
	uniqueKey: string;
	viewBox?: string;
	rectProps?: React.SVGProps<SVGRectElement>;
	containerClassName?: string;
}

const ImageWithLoader: React.FC<Props> = ({
	src,
	alt,
	uniqueKey,
	viewBox,
	rectProps,
	containerClassName,
	...props
}) => {
	const siteSettings = useSelector((state: ApplicationState) => state.siteViewSettings.item, shallowEqual);
	const colorSet = siteSettings?.colorSet ?? defaultColorSet;

	const [loading, setLoading] = React.useState<boolean>(true);

	return <>
		{loading
			&& <ContentLoader
				className={containerClassName}
				width="100%"
				height="100%"
				viewBox={viewBox}
				backgroundColor={colorSet.baseColor2}
				foregroundColor={colorSet.baseColor3}
				uniqueKey={uniqueKey}
			>
				<rect width="100%" height="100%" {...rectProps} />
			</ContentLoader>
		}
		<div className={containerClassName} hidden={loading}>
			<ImageWithSkeleton
				{...props}
				src={src ? `/remote/${src}` : imageSkeleton}
				alt={alt}
				onLoad={() => setLoading(false)}
				onError={() => setLoading(false)}
			/>
		</div>
	</>;
};

export default ImageWithLoader;
