import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { default as AntDrawer, DrawerProps } from 'antd/lib/drawer';

import { ApplicationState } from '@app/store';
import { defaultColorSet } from '@app/objects/ColorSet';
import { SiteFontTypeNames } from '@app/components/UI/SiteScheme/SiteScheme';

type Props = DrawerProps;

const Drawer: React.FC<Props> = (props) => {
	const siteSettings = useSelector((state: ApplicationState) => state.siteViewSettings.item, shallowEqual);
	const colorSet = siteSettings?.colorSet ?? defaultColorSet;

	const style = {
		'--base-color-4': colorSet.baseColor4,
		'--base-color-5': colorSet.baseColor5,
		'--h3-font': `${SiteFontTypeNames[siteSettings.h3FontType]}, sans-serif`,
	};

	return <>
		<AntDrawer
			{...props}
			style={{
				...style as React.CSSProperties,
				...props.style,
			}}
		>
			{props.children}
		</AntDrawer>
	</>;
};

export default Drawer;
