import React from 'react';

import { SocialMediaLinks } from '@app/objects/Location';

const SiteLinks: React.FC<{ socialMediaLinks: SocialMediaLinks }> = ({ socialMediaLinks }) => {
	const instagram = socialMediaLinks.instagram;
	const facebook = socialMediaLinks.facebook;

	return <div className="left-panel__links">
		{instagram && <a href={instagram} target="_blank" rel="noreferrer">Instagram</a>}
		{facebook && <a href={facebook} target="_blank" rel="noreferrer">Facebook</a>}
		<a href="https://www.northerntuna.com/" className="nobr" target="_blank" rel="noreferrer">Patient portal</a>
	</div>;
};

export default SiteLinks;
